import React, {
    Suspense
    // lazy
} from 'react';
import {
    Redirect,
    Switch,
    useLocation,
    useHistory
    // Route
} from 'react-router-dom';
import { useSelector } from 'react-redux';
import { LayoutSplashScreen, ContentRoute } from '../_metronic/layout';
// import { BuilderPage } from './pages/BuilderPage';
// import { MyPage } from './pages/MyPage';
// import {DashboardPage} from "./pages/DashboardPage";
import { Dashboard } from './pages/customs/Dashboard/Main';
import { CoinsMain } from './pages/customs/Coins/Main';
import { DonateMain } from './pages/customs/Donate/Main';
import { PayMain } from './pages/customs/Pay/Main2';
import { RecieveMain } from './pages/customs/Recieve/Main';
import { SetMain } from './pages/customs/Set/Main';
import { WithdrawMain } from './pages/customs/Withdraw/Main';

import config from './config';

// const GoogleMaterialPage = lazy(() => import('./modules/GoogleMaterialExamples/GoogleMaterialPage'));
// const ReactBootstrapPage = lazy(() => import('./modules/ReactBootstrapExamples/ReactBootstrapPage'));
// const ECommercePage = lazy(() => import('./modules/ECommerce/pages/eCommercePage'));

function usePageViews() {
    const { user } = useSelector((state) => state.auth);
    let location = useLocation();
    let history = useHistory();
    React.useEffect(
        () => {
            // console.log([ 'pageview', location.pathname ]);
            if (new Date() >= new Date(user.token_expire)) {
                history.push('/logout?expire');
            } else {
                fetch(config.crypto_url + 'user/validate', {
                    method: 'post',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json'
                        // secret: config.secret,
                    },
                    body: JSON.stringify({
                        token: user.token,
                        username: user.id_card
                    })
                })
                    .then((response) => response.json())
                    .then((result) => {
                        if (result.responsecode !== 0) {
                            history.push('/logout?expire');
                        }
                    })
                    .catch((error) => {
                        history.push('/logout?expire');
                    });
            }
        },
        [ location ]
    );
}

export default function BasePage() {
    // useEffect(() => {
    //   console.log('Base page');
    // }, []) // [] - is required if you need only one call
    // https://reactjs.org/docs/hooks-reference.html#useeffect
    usePageViews();

    return (
        <Suspense fallback={<LayoutSplashScreen />}>
            <Switch>
                {
                    /* Redirect from root URL to /dashboard. */
                    <Redirect exact from='/' to='/dashboard' />
                }
                <ContentRoute path='/dashboard' component={Dashboard} />
                <ContentRoute path='/coins' component={CoinsMain} />
                <ContentRoute path='/donate' component={DonateMain} />
                <ContentRoute path='/pay' component={PayMain} />
                <ContentRoute path='/recieve' component={RecieveMain} />
                <ContentRoute path='/set' component={SetMain} />
                <ContentRoute path='/withdraw' component={WithdrawMain} />
                {/* <ContentRoute path='/builder' component={BuilderPage} />
                <ContentRoute path='/my-page' component={MyPage} />
                <Route path='/google-material' component={GoogleMaterialPage} />
                <Route path='/react-bootstrap' component={ReactBootstrapPage} />
                <Route path='/e-commerce' component={ECommercePage} /> */}
                <Redirect to='error/error-v1' />
            </Switch>
        </Suspense>
    );
}
