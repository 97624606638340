import React from 'react';

import { toAbsoluteUrl } from '../../../../../_metronic/_helpers';

export function DonateResult(props) {
    return (
        <>
            <div className='row justify-content-center col-md-6 col-12 p-0 mt-7 m-0'>
                {Object.keys(props.response).length === 0 ? (
                    <div className='row align-items-center col-12 p-0 m-0 min-h-500px'>
                        <div className='text-center col-12 p-0'>
                            <i className='text-white fas fa-exclamation-circle fa-5x' />
                            <h1 className='col mt-5 text-white'>มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง</h1>
                        </div>
                    </div>
                ) : (
                    <>
                        {props.response.responsecode === 0 ? (
                            <div className='col-12 p-0'>
                                <div className='text-center'>
                                    <img
                                        className='max-h-200px'
                                        alt='success-icon'
                                        src={toAbsoluteUrl('/media/icons/transfer-success.png')}
                                    />
                                    <h2 className='mt-7 text-white'>โอนเหรียญเรียบร้อย</h2>
                                    {/* <p className='m-0'>
                                        ดำเนินการโอนเหรียญร้อยแล้ว
                                    </p> */}
                                </div>
                            </div>
                        ) : (
                            <div className='col-12 p-0'>
                                <div className='text-center'>
                                    <img
                                        className='max-h-200px'
                                        alt='failed-icon'
                                        src={toAbsoluteUrl('/media/icons/transfer-failed.png')}
                                    />
                                    <h2 className='mt-7 text-white'>โอนเหรียญไม่สำเร็จ</h2>
                                    {/* <p className='m-0'>ไม่สามารถสั่งซื้อสินค้าได้</p> */}
                                    <p className='text-white'>
                                        กรุณาติดต่อเจ้าหน้าที่ ที่<a
                                            className='text-danger text-hover-light-danger'
                                            href={`https://line.me/ti/p/~@dealersim`}
                                            target='_blank'
                                            rel='noopener noreferrer'
                                        >
                                            เจ้าหน้าที่
                                        </a>
                                    </p>
                                </div>
                            </div>
                        )}
                    </>
                )}
            </div>
        </>
    );
}
