import React, { useEffect } from 'react';

import { Link } from 'react-router-dom';

import { QRCode } from 'react-qrcode-logo';

import html2canvas from 'html2canvas';

import Moment from 'moment';

import 'moment/locale/th';

import $ from 'jquery';

import { toAbsoluteUrl } from '../../../../../_metronic/_helpers';

export function PayResult(props) {
    // useEffect(() => {
    //     $(document).ready(function() {
    //         $('#react-qrcode-logo').css('border', '2px solid');
    //     });
    // }, []);

    const handleDownload = () => {
        let d = new Date();
        html2canvas(document.querySelector('#react-qrcode-logo')).then(function(canvas) {
            const link = document.createElement('a');
            link.download = `payment-${Moment(d).format('YYYYMMDDhhmmss')}.png`;
            link.href = canvas.toDataURL();
            link.click();
        });
    };
    return (
        <div>
            <div className='row justify-content-between m-0 mb-7'>
                <Link to='/coins' className='btn btn-link-primary my-auto'>
                    <i className='fas fa-angle-double-left icon-md py-2' />ย้อนกลับ
                </Link>
            </div>
            <div className='row justify-content-center m-0'>
                <div className='col-12 p-0 text-center text-white'>
                    <div className='font-size-h1 mb-5'>บริจาค</div>
                </div>
                {Object.keys(props.response).length <= 0 ? (
                    <div className='row align-items-center col-12 p-0 m-0 min-h-500px'>
                        <div className='text-center col-12 p-0'>
                            <i className='text-white fas fa-exclamation-circle fa-5x' />
                            <h1 className='col mt-5 text-white'>มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง</h1>
                        </div>
                    </div>
                ) : (
                    <>
                        {props.response.responsecode !== 0 ? (
                            <>
                                {props.response.responsecode === 100 ? (
                                    <div className='row align-items-center col-12 p-0 m-0 min-h-500px'>
                                        <div className='text-center col-12 p-0'>
                                            <i className='text-white fas fa-exclamation-circle fa-5x' />
                                            <h1 className='col mt-5 text-white'>มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง</h1>
                                        </div>
                                    </div>
                                ) : props.response.responsecode === 101 ? (
                                    <div className='row align-items-center col-12 p-0 m-0 min-h-500px'>
                                        <div className='text-center col-12 p-0'>
                                            <i className='text-white fas fa-exclamation-circle fa-5x' />
                                            <h1 className='col mt-5 text-white'>ไม่พบเหรียญประเภทนี้ในระบบ กรุณาลองใหม่อีกครั้ง</h1>
                                        </div>
                                    </div>
                                ) : props.response.responsecode === 102 ? (
                                    <div className='row align-items-center col-12 p-0 m-0 min-h-500px'>
                                        <div className='text-center col-12 p-0'>
                                            <i className='text-white fas fa-exclamation-circle fa-5x' />
                                            <h1 className='col mt-5 text-white'>ไม่สามารถสร้างบัญชีได้ กรุณาลองใหม่อีกครั้ง</h1>
                                        </div>
                                    </div>
                                ) : props.response.responsecode === 103 ? (
                                    <div className='row align-items-center col-12 p-0 m-0 min-h-500px'>
                                        <div className='text-center col-12 p-0'>
                                            <i className='text-white fas fa-exclamation-circle fa-5x' />
                                            <h1 className='col mt-5 text-white'>ไม่สามารถสร้างรายการได้ กรุณาลองใหม่อีกครั้ง</h1>
                                        </div>
                                    </div>
                                ) : props.response.responsecode === 104 ? (
                                    <div className='row align-items-center col-12 p-0 m-0 min-h-500px'>
                                        <div className='text-center col-12 p-0'>
                                            <i className='text-white fas fa-exclamation-circle fa-5x' />
                                            <h1 className='col mt-5 text-white'>ไม่สามารถสร้างคิวอาร์โค้ดได้ กรุณาลองใหม่อีกครั้ง</h1>
                                        </div>
                                    </div>
                                ) : props.response.responsecode === 105 ? (
                                    <div className='row align-items-center col-12 p-0 m-0 min-h-500px'>
                                        <div className='text-center col-12 p-0'>
                                            <i className='text-white fas fa-exclamation-circle fa-5x' />
                                            <h1 className='col mt-5 text-white'>รายการมีปัญหา กรุณาติดต่อเจ้าหน้าที่</h1>
                                        </div>
                                    </div>
                                ) : (
                                    <div className='row align-items-center col-12 p-0 m-0 min-h-500px'>
                                        <div className='text-center col-12 p-0'>
                                            <i className='text-white fas fa-exclamation-circle fa-5x' />
                                            <h1 className='col mt-5 text-white'>มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง</h1>
                                        </div>
                                    </div>
                                )}
                            </>
                        ) : (
                            <>
                                {/* <div className='col-md-3 col-10 p-7 bg-light text-center'>
                                    <QRCode
                                        value={props.response.qr}
                                        size={256}
                                        eyeRadius={10}
                                        logoImage={toAbsoluteUrl('/media/logos/main-logo.png')}
                                        logoWidth={100}
                                    />
                                    <div className='row justify-content-center m-0 mt-5'>
                                        <button
                                            type='button'
                                            className='btn btn-light btn-hover-primary btn-sm mx-2'
                                            onClick={handleDownload}
                                        >
                                            บันทึก
                                        </button>
                                    </div>
                                </div> */}
                                <div className='col-12 row justify-content-center mt-7 m-0 p-0'>
                                    <div className='col-md-4 row m-0 p-0 justify-content-between'>
                                        <div className='font-size-h3 col-md-6 col-12 text-white'>Reference</div>
                                        <div className='font-size-h3 col-md-4 col-12 text-white text-right'>{props.response.order_id}</div>
                                    </div>
                                </div>
                                <div className='col-12 row justify-content-center m-0 p-0'>
                                    <div className='col-md-4 row m-0 p-0 justify-content-between'>
                                        <div className='font-size-h3 col-md-6 col-12 text-white'>ยอดรวม</div>
                                        <div className='font-size-h3 col-md-4 col-12 text-white text-right'>{props.price}</div>
                                    </div>
                                </div>
                                {props.coin !== 'yakt' ? (
                                    <>
                                        <div className='col-12 row justify-content-center m-0 p-0'>
                                            <div className='col-md-4 row m-0 p-0 justify-content-between'>
                                                <div className='font-size-h3 col-md-6 col-12 text-white'>จำนวนเหรียญ BNB</div>
                                                <div className='font-size-h3 col-md-4 col-12 text-white text-right'>{props.bnbamount}</div>
                                            </div>
                                        </div>
                                        <div className='col-12 row justify-content-center m-0 p-0'>
                                            <div className='col-md-4 row m-0 p-0 justify-content-between'>
                                                <div className='font-size-h3 col-md-6 col-12 text-white'>จำนวนเหรียญ StarVerse</div>
                                                <div className='font-size-h3 col-md-4 col-12 text-white text-right'>{props.starverseamount}</div>
                                            </div>
                                        </div>
                                    </>
                                ) : (
                                    <div className='col-12 row justify-content-center m-0 p-0'>
                                        <div className='col-md-4 row m-0 p-0 justify-content-between'>
                                            <div className='font-size-h3 col-md-6 col-12 text-white'>จำนวนเหรียญ YakToken</div>
                                            <div className='font-size-h3 col-md-4 col-12 text-white text-right'>{props.yaktamount}</div>
                                        </div>
                                    </div>
                                )}
                                <div className='col-md-8 col-12 text-center mx-auto mt-7'>
                                    <img className='max-h-md-500px max-h-300px' src={toAbsoluteUrl('/media/misc/bank-image.jpg')} />
                                </div>
                            </>
                        )}
                    </>
                )}
            </div>
        </div>
    );
}
