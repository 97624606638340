import React, { useEffect } from 'react';

import { toAbsoluteUrl } from '../../../../_metronic/_helpers';

export function Dashboard() {
    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://widget.nomics.com/embed.js';
        script.async = true;
        document.body.appendChild(script);
        // $(document).ready(function() {
        //     $('#bnb-graph').addClass('col-12 mx-auto');
        // });
        // document.getElementById('bnb-graph').classList.add('col-12 mx-auto')
        return () => {
            document.body.removeChild(script);
            // document.getElementById('bnb-graph').classList.add('col-12 mx-auto')
        };
    }, []);

    return (
        <div className='row' id='dashboard'>
            <div className='col-md-7 col-12 mx-auto'>
                <div
                    className='nomics-ticker-widget'
                    id='bnb-graph'
                    data-name='Coin'
                    data-base='BNB'
                    data-quote='USDT'
                />
            </div>
            <div className='col-md-8 col-12 text-center mx-auto mt-7'>
                <img className='max-h-md-500px max-h-300px' src={toAbsoluteUrl('/media/misc/bank-image.jpg')} />
            </div>
        </div>
    );
}
