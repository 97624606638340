import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { Link, useHistory } from 'react-router-dom';

import Swal from 'sweetalert2/dist/sweetalert2.js';

import config from '../../../config';

const SwalBs = Swal.mixin({
    customClass: {
        popup: 'rounded-lg',
        confirmButton: 'btn btn-success rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto',
        cancelButton: 'btn btn-danger rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto'
    },
    buttonsStyling: false
});

export function SetMain(props) {
    const { user } = useSelector((state) => state.auth);
    let history = useHistory();

    const [ loading, setLoading ] = useState(false);
    const [ id, setId ] = useState('');
    const [ address, setAddress ] = useState('');
    const [ network, setNetwork ] = useState('Smart Chain');

    const getQueryVariable = (variable) => {
        var query = props.location.search.substring(1);
        var vars = query.split('&');
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split('=');
            if (pair[0] === variable) {
                return pair[1];
            }
        }
        return false;
    };

    useEffect(() => {
        let queryparam = getQueryVariable('id');
        if (queryparam) {
            setId(queryparam);
        }
    }, []);

    const setValue = () => {
        if (address === '') {
            SwalBs.fire({
                // title: 'ยกเลิกคำสั่ง',
                html: `กรุณากรอก Address ให้เรียบร้อย แล้วลองใหม่อีกครั้ง`,
                icon: 'warning',
                // showCancelButton: true,
                confirmButtonText: 'รับทราบ',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                heightAuto: false
            });
            return;
        }
        if (network === '') {
            SwalBs.fire({
                // title: 'ยกเลิกคำสั่ง',
                html: `กรุณากรอก Network ให้เรียบร้อย แล้วลองใหม่อีกครั้ง`,
                icon: 'warning',
                // showCancelButton: true,
                confirmButtonText: 'รับทราบ',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                heightAuto: false
            });
            return;
        }
        setLoading(true);
        fetch(config.crypto_url + 'account/update', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify({
                token: user.token,
                id: id,
                update: {
                    withdraw_address: address,
                    withdraw_network: network
                }
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                if (result.responsecode === 0) {
                    SwalBs.fire({
                        // title: 'ยกเลิกคำสั่ง',
                        html: `ดำเนินการตั้งค่าเรียบร้อย`,
                        icon: 'success',
                        // showCancelButton: true,
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        heightAuto: false
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            history.push('/coins');
                        }
                    });
                } else {
                    SwalBs.fire({
                        // title: 'ยกเลิกคำสั่ง',
                        html: `ไม่สามารถตั้งค่าได้ กรุณาลองใหม่อีกครั้ง`,
                        icon: 'warning',
                        // showCancelButton: true,
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        heightAuto: false
                    });
                }
            })
            .catch((error) => {
                setLoading(false);
                SwalBs.fire({
                    // title: 'ยกเลิกคำสั่ง',
                    html: `มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง`,
                    icon: 'error',
                    // showCancelButton: true,
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    heightAuto: false
                });
            });
    };
    return (
        <div>
            {loading ? (
                <div className='d-flex flex-column flex-root' id='loading-section'>
                    <div className='row justify-content-center align-items-center overlay p-0 m-0' id='overlay'>
                        <div className='col col-sm-6 col-lg-12 h-100 p-0'>
                            <div className='bounce col-12'>
                                <div className='bounce1' />
                                <div className='bounce2' />
                                <div className='bounce3' />
                            </div>
                            <h1 className='col loading-txt'>กรุณารอสักครู่</h1>
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                    <div className='row justify-content-between m-0 mb-7'>
                        <Link to='/coins' className='btn btn-link-primary my-auto'>
                            <i className='fas fa-angle-double-left icon-md py-2' />ย้อนกลับ
                        </Link>
                    </div>
                    <div className='row justify-content-center m-0'>
                        <div className='col-12 p-0 text-center text-white'>
                            <div className='font-size-h1 mb-5'>ตั้งค่าข้อมูลบัญชี</div>
                        </div>
                        <div className='col-md-8 col-12 p-0 mt-7'>
                            <div className='form-group row mx-0'>
                                <label className='col-form-label col-lg-3 text-white'>Address</label>
                                <div className='col-lg-9'>
                                    <input
                                        type='text'
                                        className='form-control'
                                        value={address}
                                        onChange={(e) => setAddress(e.target.value)}
                                    />
                                </div>
                            </div>
                            <div className='form-group row mx-0'>
                                <label className='col-form-label col-lg-3 text-white'>Network</label>
                                <div className='col-lg-9'>
                                    <input
                                        type='text'
                                        className='form-control'
                                        readOnly
                                        value={network}
                                        // onChange={(e) => setNetwork(e.target.value)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='col-12 p-0 mt-7 text-center'>
                            <button
                                type='button'
                                className='btn btn-primary text-uppercase font-weight-bolder px-15 py-3 rounded-lg'
                                onClick={setValue}
                            >
                                ตั้งค่า
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
