import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { Link } from 'react-router-dom';

import NumberFormat from 'react-number-format';

import { toAbsoluteUrl } from '../../../../_metronic/_helpers';

import config from '../../../config';

export function CoinsMain() {
    const { user } = useSelector((state) => state.auth);

    const [ loading, setLoading ] = useState(false);
    const [ loadingbalance, setLoadingBalance ] = useState(false);
    const [ coins, setCoins ] = useState({});
    const [ customcoins, setCustomCoins ] = useState({});
    const [ balancecoins, setBalanceCoins ] = useState({});

    useEffect(() => {
        getCoins();
    }, []);

    const getCoins = () => {
        setLoading(true);
        fetch(config.crypto_url + 'product/find', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify({
                category_id: '3'
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                setCoins(result);
                let coin_object = {};
                if (result.responsecode === 0) {
                    for (const index in result.info) {
                        // console.log(result.info[index]);
                        let coin_value = result.info[index];
                        // coin_value['balance'] = null;
                        // coin_value['number'] = '';
                        coin_value['balance'] = 0;
                        // coin_value['address'] = '';
                        // coin_value['withdraw_address'] = '';
                        // coin_value['withdraw_network'] = '';
                        // coin_value['withdraw_pending'] = '';
                        // coin_value['pending_balance'] = '';
                        coin_object[result.info[index]['name']] = coin_value;
                    }
                    // console.log(coin_object);
                    setCustomCoins(coin_object);
                }
                getBalace(coin_object);
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const getBalace = (object) => {
        setLoadingBalance(true);
        fetch(config.crypto_url + 'account/get-balance', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify({
                token: user.token
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoadingBalance(false);
                // console.log(result);
                if (result.responsecode === 0) {
                    let coin_object = object;
                    // console.log(coin_object);
                    for (const index in result.account) {
                        // console.log(result.account[index]);
                        // let coin_value = object[result.account[index]['type']];
                        // for (const )
                        if (Object.keys(object).includes(result.account[index]['type'])) {
                            // console.log('test');
                            // console.log(object[result.account[index]['type']]);
                            let coin_value = object[result.account[index]['type']];
                            coin_value['number'] = result.account[index]['number'];
                            coin_value['balance'] = result.account[index]['balance'];
                            coin_value['address'] = result.account[index]['address'];
                            coin_value['withdraw_address'] = result.account[index]['withdraw_address'];
                            coin_value['withdraw_network'] = result.account[index]['withdraw_network'];
                            coin_value['withdraw_pending'] = result.account[index]['withdraw_pending'];
                            coin_value['pending_balance'] = result.account[index]['pending_balance'];
                            coin_object[object[result.account[index]['type']]['name']] = coin_value;
                        }
                        // let coin_value = object[result.account[index]['type']];
                        // coin_value['balance'] = 1;
                        // console.log(coin_value);
                        // coin_object[object[index]['name']] = coin_value;
                    }
                    console.log(coin_object);
                    setBalanceCoins(coin_object);
                }
            })
            .catch((error) => {
                setLoadingBalance(false);
            });
    };
    return (
        <div>
            {loading ? (
                <div className='d-flex flex-column flex-root' id='loading-section'>
                    <div className='row justify-content-center align-items-center overlay p-0 m-0' id='overlay'>
                        <div className='col col-sm-6 col-lg-12 h-100 p-0'>
                            <div className='bounce col-12'>
                                <div className='bounce1' />
                                <div className='bounce2' />
                                <div className='bounce3' />
                            </div>
                            <h1 className='col loading-txt'>กรุณารอสักครู่</h1>
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                    {Object.keys(coins).length === 0 ? (
                        <div className='row align-items-center col-12 p-0 m-0 min-h-500px'>
                            <div className='text-center col-12 p-0'>
                                <i className='text-white fas fa-exclamation-circle fa-5x' />
                                <h1 className='col mt-5 text-white'>มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง</h1>
                            </div>
                        </div>
                    ) : (
                        <div>
                            {coins.responsecode === 0 ? (
                                <div className='table-responsive-lg bg-white'>
                                    <table className='table table-striped mb-0'>
                                        <thead className='thead-dark'>
                                            <tr>
                                                <th scope='col'>Assets</th>
                                                <th scope='col'>Balance</th>
                                                {/* <th scope='col'>BTC Value</th> */}
                                                <th className='text-right' scope='col'>
                                                    Action
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {Object.values(customcoins).map((object, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <th scope='row'>
                                                            <div className='d-flex align-items-center'>
                                                                {/* symbol-circle */}
                                                                <div className='symbol symbol-40 mr-5'>
                                                                    {/* <div className='symbol-label'>
                                                                        <i className='fas fa-sim-card fa-2x' />
                                                                    </div> */}
                                                                    <img
                                                                        className='h-40px'
                                                                        alt={object.name}
                                                                        src={
                                                                            object.name === 'bnb' ? (
                                                                                toAbsoluteUrl(
                                                                                    '/media/logos/binance-logo.png'
                                                                                )
                                                                            ) : object.name === 'starverse' ? (
                                                                                toAbsoluteUrl(
                                                                                    '/media/logos/starverse-logo.png'
                                                                                )
                                                                            ) : object.name === 'yakt' ? (
                                                                                toAbsoluteUrl(
                                                                                    '/media/logos/yakt-logo.png'
                                                                                )
                                                                             ) : null
                                                                        }
                                                                    />
                                                                </div>
                                                                <div className='d-flex flex-column font-weight-bold'>
                                                                    <span className='font-weight-bolder'>
                                                                        {object.name === 'bnb' ? 'BNB' : object.name === 'starverse' ? 'StarVerse' : object.name === 'yakt' ? 'YakToken' : null}
                                                                    </span>
                                                                    <span className='text-muted font-weight-bold'>
                                                                        {object.description === 'bnb' ? 'Binance' : object.name === 'starverse' ? 'StarVerse' : object.name === 'yakt' ? 'YakToken' : null}
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </th>
                                                        <td className='align-middle'>
                                                            {loadingbalance ? (
                                                                <i className='fas fa-spinner fa-pulse' />
                                                            ) : (
                                                                <>
                                                                    {Object.keys(balancecoins).length > 0 && (
                                                                        <>
                                                                            {object.withdraw_pending === 'yes' ? (
                                                                                <>
                                                                                    <NumberFormat
                                                                                        value={parseFloat(balancecoins[object.name]['balance'])}
                                                                                        displayType={'text'}
                                                                                        decimalScale={2}
                                                                                        fixedDecimalScale={true}
                                                                                        thousandSeparator={true}
                                                                                        // suffix={' บาท'}
                                                                                    />
                                                                                    {' '}
                                                                                    <span className='text-warning'>
                                                                                        (<NumberFormat
                                                                                        value={parseFloat(balancecoins[object.name]['pending_balance'])}
                                                                                        displayType={'text'}
                                                                                        decimalScale={2}
                                                                                        fixedDecimalScale={true}
                                                                                        thousandSeparator={true}
                                                                                        // suffix={' บาท'}
                                                                                    />)
                                                                                    </span>
                                                                                </>
                                                                            ) : (
                                                                                <NumberFormat
                                                                                    value={parseFloat(balancecoins[object.name]['balance'])}
                                                                                    displayType={'text'}
                                                                                    decimalScale={2}
                                                                                    fixedDecimalScale={true}
                                                                                    thousandSeparator={true}
                                                                                    // suffix={' บาท'}
                                                                                />
                                                                            )}
                                                                            {/* {balancecoins[object.name]['balance'] !== null ? ( */}
                                                                                {/* <NumberFormat
                                                                                    value={parseFloat(balancecoins[object.name]['balance'])}
                                                                                    displayType={'text'}
                                                                                    decimalScale={2}
                                                                                    fixedDecimalScale={true}
                                                                                    thousandSeparator={true}
                                                                                    // suffix={' บาท'}
                                                                                /> */}
                                                                            {/* ) : (
                                                                                <NumberFormat
                                                                                    value={parseFloat(0)}
                                                                                    displayType={'text'}
                                                                                    decimalScale={2}
                                                                                    fixedDecimalScale={true}
                                                                                    thousandSeparator={true}
                                                                                    // suffix={' บาท'}
                                                                                />
                                                                            )} */}
                                                                        </>
                                                                    )}
                                                                </>
                                                            )}
                                                        </td>
                                                        {/* <td className='align-middle'>
                                                            <NumberFormat
                                                                value={parseFloat(object.rate_price)}
                                                                displayType={'text'}
                                                                decimalScale={2}
                                                                fixedDecimalScale={true}
                                                                thousandSeparator={true}
                                                                // suffix={' บาท'}
                                                            />
                                                        </td> */}
                                                        <td className='align-middle text-right'>
                                                            {loadingbalance ? (
                                                                <i className='fas fa-spinner fa-pulse' />
                                                            ) : (
                                                                <>
                                                                    {Object.keys(balancecoins).length > 0 && (
                                                                        <>
                                                                            {Object.keys(balancecoins[object.name]).includes('withdraw_address') ? (
                                                                                <>
                                                                                    {balancecoins[object.name]['withdraw_address'] === '' ? (
                                                                                        <>
                                                                                            <Link
                                                                                                to={`/set?id=${object.number}`}
                                                                                                className='btn btn-light btn-hover-primary btn-sm mx-2'
                                                                                            >
                                                                                                ตั้งค่า
                                                                                            </Link>
                                                                                            <Link
                                                                                                to='/donate'
                                                                                                className='btn btn-light btn-hover-primary btn-sm mx-2'
                                                                                            >
                                                                                                โอน
                                                                                            </Link>
                                                                                            {object.name !== 'yakt' && (
                                                                                                <Link
                                                                                                    to={`/pay?coin=${object.name}`}
                                                                                                    className='btn btn-light btn-hover-primary btn-sm mx-2'
                                                                                                >
                                                                                                    บริจาค
                                                                                                </Link>
                                                                                            )}
                                                                                            <Link
                                                                                                to={`/recieve?coin=${object.name}`}
                                                                                                className='btn btn-light btn-hover-primary btn-sm mx-2'
                                                                                            >
                                                                                                รับ
                                                                                            </Link>
                                                                                        </>
                                                                                    ) : (
                                                                                        <>
                                                                                            {object.withdraw_pending === 'yes' ? (
                                                                                                <div className='btn btn-warning btn-sm mx-2'>
                                                                                                    ระหว่างดำเนินการ
                                                                                                </div>
                                                                                            ) : (
                                                                                                <Link
                                                                                                    to={`/withdraw?coin=${object.name}&balance=${object.balance}&address=${object.address}&withdrawaddress=${object.withdraw_address}&withdrawnetwork=${object.withdraw_network}`}
                                                                                                    className='btn btn-light btn-hover-primary btn-sm mx-2'
                                                                                                >
                                                                                                    ถอน
                                                                                                </Link>
                                                                                            )}
                                                                                            <Link
                                                                                                to='/donate'
                                                                                                className='btn btn-light btn-hover-primary btn-sm mx-2'
                                                                                            >
                                                                                                โอน
                                                                                            </Link>
                                                                                            {object.name !== 'yakt' && (
                                                                                                <Link
                                                                                                    to={`/pay?coin=${object.name}`}
                                                                                                    className='btn btn-light btn-hover-primary btn-sm mx-2'
                                                                                                >
                                                                                                    บริจาค
                                                                                                </Link>
                                                                                            )}
                                                                                            <Link
                                                                                                to={`/recieve?coin=${object.name}`}
                                                                                                className='btn btn-light btn-hover-primary btn-sm mx-2'
                                                                                            >
                                                                                                รับ
                                                                                            </Link>
                                                                                        </>
                                                                                    )}
                                                                                </>
                                                                            ) : (
                                                                                <>
                                                                                    {/* <Link
                                                                                        to='/donate'
                                                                                        className='btn btn-light btn-hover-primary btn-sm mx-2'
                                                                                    >
                                                                                        โอน
                                                                                    </Link> */}
                                                                                    {object.name !== 'yakt' && (
                                                                                        <Link
                                                                                            to={`/pay?coin=${object.name}`}
                                                                                            className='btn btn-light btn-hover-primary btn-sm mx-2'
                                                                                        >
                                                                                            บริจาค
                                                                                        </Link>
                                                                                    )}
                                                                                    {/* <Link
                                                                                        to={`/recieve?coin=${object.name}`}
                                                                                        className='btn btn-light btn-hover-primary btn-sm mx-2'
                                                                                    >
                                                                                        รับ
                                                                                    </Link> */}
                                                                                </>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </>
                                                            )}
                                                            {/* <Link
                                                                to='/donate'
                                                                className='btn btn-light btn-hover-primary btn-sm mx-2'
                                                            >
                                                                โอน
                                                            </Link>
                                                            <Link
                                                                to={`/pay?coin=${object.name}`}
                                                                className='btn btn-light btn-hover-primary btn-sm mx-2'
                                                            >
                                                                ซื้อ
                                                            </Link>
                                                            <Link
                                                                to={`/recieve?coin=${object.name}`}
                                                                className='btn btn-light btn-hover-primary btn-sm mx-2'
                                                            >
                                                                รับ
                                                            </Link> */}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        </tbody>
                                    </table>
                                </div>
                            ) : null}
                        </div>
                    )}
                </div>
            )}
        </div>
    );
}
