import React, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { connect } from 'react-redux';
import {
    // FormattedMessage
    injectIntl
} from 'react-intl';
import * as auth from '../_redux/authRedux';
// import { login } from '../_redux/authCrud';

import config from '../../../config';

/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
    username: '',
    password: ''
};

function Login(props) {
    // const { intl } = props;
    const [ loading, setLoading ] = useState(false);
    const [ passwordShown, setPasswordShown ] = useState(false);
    const [ expire, setExpire ] = useState(false);
    const LoginSchema = Yup.object().shape({
        username: Yup.string()
            // .email('Wrong email format')
            // .min(3, 'Minimum 3 symbols')
            // .max(50, 'Maximum 50 symbols')
            .required('กรุณากรอกบัตรประชาชน/พาสปอร์ต'),
        password: Yup.string()
            // .min(3, 'Minimum 3 symbols')
            // .max(50, 'Maximum 50 symbols')
            .required('กรุณากรอกเบอร์โทรศัพท์ปันสุข')
    });

    useEffect(() => {
        // console.log(props);
        if (props.location.pathname.includes('/expire')) {
            setExpire(true);
        }
    }, [])

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return 'is-invalid';
        }

        // if (formik.touched[fieldname] && !formik.errors[fieldname]) {
        //     return 'is-valid';
        // }

        return '';
    };

    const formik = useFormik({
        initialValues,
        validationSchema: LoginSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            enableLoading();
            fetch(config.crypto_url + 'user/login', {
                method: 'post',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json'
                    // secret: config.secret,
                },
                body: JSON.stringify({
                    username: values.username,
                    password: values.password
                })
            })
                .then((response) => response.json())
                .then((result) => {
                    disableLoading();
                    if (result.responsecode === 0) {
                        let object = result.info;
                        // let d = new Date();
                        // d.setSeconds(d.getSeconds() + 10);
                        // d.setMinutes(d.getMinutes() + 60);
                        // d.setMinutes(d.getMinutes() + 60);
                        // d.setYear(d.getFullYear() + 1);
                        // object['token_expire'] = d;
                        props.newLogin(object);
                    } else {
                        setSubmitting(false);
                        if (result.responsecode === 101) {
                            setStatus('ไม่พบข้อมูลของคุณในระบบ');
                        } else if (result.responsecode === 102) {
                            setStatus(
                                'หมายเลขบัตรประชาชน/พาสปอร์ต หรือเบอร์โทรศัพท์ปันสุขไม่ถูกต้อง กรุณาลองใหม่อีกครั้ง'
                            );
                        } else {
                            setStatus('มีบางอย่างผิดพลาด กรุณาติดต่อเจ้าหน้าที่');
                        }
                    }
                })
                .catch((error) => {
                    disableLoading();
                    setSubmitting(false);
                    setStatus('มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง');
                });
            // setTimeout(() => {
            //     login(values.username, values.password)
            //         .then(({ data: { accessToken } }) => {
            //             disableLoading();
            //             props.login(accessToken);
            //         })
            //         .catch(() => {
            //             disableLoading();
            //             setSubmitting(false);
            //             setStatus(
            //                 intl.formatMessage({
            //                     id: 'AUTH.VALIDATION.INVALID_LOGIN'
            //                 })
            //             );
            //         });
            // }, 1000);
        }
    });

    const maxLengthCheck = (object) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength);
        }
    };

    return (
        <div className='login-form login-signin' id='kt_login_signin_form'>
            {/* begin::Head */}
            <div className='text-center mb-10 mb-lg-20'>
                <h3 className='font-size-h1'>
                    ลงชื่อเข้าใช้
                    {/* <FormattedMessage id='AUTH.LOGIN.TITLE' /> */}
                </h3>
                <p className='text-muted font-weight-bold'>ยินดีต้อนรับกลับ! เข้าสู่ระบบด้วยเบอร์ปันสุข</p>
            </div>
            {/* end::Head */}

            {/*begin::Form*/}
            <form onSubmit={formik.handleSubmit} className='form fv-plugins-bootstrap fv-plugins-framework'>
                {formik.status && (
                    <div className='mb-10 alert alert-custom alert-light-danger alert-dismissible rounded-lg'>
                        <div className='alert-text font-weight-bold'>{formik.status}</div>
                    </div>
                )}
                {expire && (
                    <div className='mb-10 alert alert-custom alert-light-danger alert-dismissible rounded-lg'>
                        <div className='alert-text font-weight-bold'>เซสชันหมดอายุ โปรดเข้าสู่ระบบอีกครั้งเพื่อต่ออายุเซสชัน</div>
                    </div>
                )}
                {/* : (
                    <div className='mb-10 alert alert-custom alert-light-info alert-dismissible rounded-lg'>
                        <div className='alert-text '>
                            Use account <strong>admin@demo.com</strong> and password <strong>demo</strong> to continue.
                        </div>
                    </div>
                )} */}

                <div className='form-group fv-plugins-icon-container'>
                    <label className='font-size-h6 font-weight-bolder'>บัตรประชาชน/พาสปอร์ต</label>
                    <input
                        type='text'
                        className={`form-control form-control-solid h-auto py-5 px-6 rounded-lg ${getInputClasses(
                            'username'
                        )}`}
                        name='username'
                        maxLength='13'
                        onInput={maxLengthCheck}
                        {...formik.getFieldProps('username')}
                    />
                    {formik.touched.username && formik.errors.username ? (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.username}</div>
                        </div>
                    ) : null}
                </div>
                <div className='form-group fv-plugins-icon-container'>
                    <label className='font-size-h6 font-weight-bolder'>
                        เบอร์โทรศัพท์ปันสุข
                        <i
                            className={`fa ${!passwordShown ? 'fa-eye' : 'fa-eye-slash'} cursor-pointer ml-3`}
                            onClick={() => setPasswordShown(!passwordShown)}
                            aria-hidden='true'
                        />
                    </label>
                    <input
                        type={!passwordShown ? 'password' : 'text'}
                        inputMode='tel'
                        className={`form-control form-control-solid h-auto py-5 px-6 rounded-lg ${getInputClasses(
                            'password'
                        )}`}
                        name='password'
                        onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                                if (event.key !== 'Enter') {
                                    event.preventDefault();
                                }
                            }
                        }}
                        maxLength='10'
                        onInput={maxLengthCheck}
                        {...formik.getFieldProps('password')}
                    />
                    {formik.touched.password && formik.errors.password ? (
                        <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>{formik.errors.password}</div>
                        </div>
                    ) : null}
                </div>
                <div className='form-group d-flex flex-wrap justify-content-end align-items-center'>
                    {/* <Link
            to="/auth/forgot-password"
            className="text-dark-50 text-hover-primary my-3 mr-2"
            id="kt_login_forgot"
          >
            <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
          </Link> */}
                    <button
                        id='kt_login_signin_submit'
                        type='submit'
                        disabled={formik.isSubmitting}
                        className={`btn btn-primary font-weight-bold px-9 py-4 my-3 rounded-lg`}
                    >
                        <span>เข้าสู่ระบบ</span>
                        {loading && <span className='ml-3 spinner spinner-white' />}
                    </button>
                </div>
            </form>
            {/*end::Form*/}
        </div>
    );
}

export default injectIntl(connect(null, auth.actions)(Login));
