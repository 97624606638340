/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { useEffect } from 'react';
import { Redirect, Switch, Route, useHistory } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import { Layout } from '../_metronic/layout';
import BasePage from './BasePage';
import { Logout, AuthPage } from './modules/Auth';
import ErrorsPage from './modules/ErrorsExamples/ErrorsPage';
import config from './config';

export function Routes() {
    const { user } = useSelector((state) => state.auth);
    let history = useHistory();

    useEffect(() => {
        if (user !== null && user !== undefined) {
            if (new Date() >= new Date(user.token_expire)) {
                history.push('/logout?expire');
            } else {
                checkToken();
            }
        }
    }, []);

    const checkToken = () => {
        fetch(config.crypto_url + 'user/validate', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify({
                token: user.token,
                username: user.id_card
            })
        })
            .then((response) => response.json())
            .then((result) => {
                if (result.responsecode !== 0) {
                    history.push('/logout?expire');
                }
            })
            .catch((error) => {
                history.push('/logout?expire');
            });
    }

    const { isAuthorized } = useSelector(
        ({ auth }) => ({
            isAuthorized: auth.user != null
        }),
        shallowEqual
    );

    return (
        <Switch>
            {!isAuthorized ? (
                /*Render auth page when user at `/auth` and not authorized.*/
                <Route>
                    <AuthPage />
                </Route>
            ) : (
                /*Otherwise redirect to root page (`/`)*/
                <Redirect from='/auth' to='/' />
            )}

            <Route path='/error' component={ErrorsPage} />
            <Route path='/logout' component={Logout} />

            {!isAuthorized ? (
                /*Redirect to `/auth` when user is not authorized*/
                <Redirect to='/' />
            ) : (
                <Layout>
                    <BasePage />
                </Layout>
            )}
        </Switch>
    );
}
