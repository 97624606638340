import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { Link } from 'react-router-dom';

import { QRCode } from 'react-qrcode-logo';

import html2canvas from 'html2canvas';

import Moment from 'moment';

import 'moment/locale/th';

import { ToastContainer, toast } from 'react-toastify';

import config from '../../../config';

export function RecieveMain(props) {
    const { user } = useSelector((state) => state.auth);

    const [ loading, setLoading ] = useState(false);
    const [ address, setAddress ] = useState('');
    const [ copy, setCopy ] = useState(false);

    const getQueryVariable = (variable) => {
        var query = props.location.search.substring(1);
        var vars = query.split('&');
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split('=');
            if (pair[0] === variable) {
                return pair[1];
            }
        }
        return false;
    };

    useEffect(() => {
        getBalace();
    }, []);

    const handleDownload = () => {
        let d = new Date();
        html2canvas(document.querySelector('#react-qrcode-logo')).then(function(canvas) {
            const link = document.createElement('a');
            link.download = `wallet-${Moment(d).format('YYYYMMDDhhmmss')}.png`;
            link.href = canvas.toDataURL();
            link.click();
        });
    };

    const copyText = () => {
        var copyText = document.getElementById('walletid');
        // console.log(copyText);
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        document.execCommand('copy');
        toast.success(`คัดลอก Wallet ID ของคุณลงคลิปบอร์ดแล้ว`, {
            onOpen: () => setCopy(true),
            onClose: () => setCopy(false),
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: true,
            draggable: false,
            progress: undefined
        });
    };

    const getBalace = () => {
        setLoading(true);
        fetch(config.crypto_url + 'account/get-balance', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify({
                token: user.token
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                if (result.responsecode === 0) {
                    let coin = getQueryVariable('coin');
                    for (const index in result.account) {
                        console.log(result.account[index]['type']);
                        console.log(coin);
                        if (result.account[index]['type'] === coin) {
                            console.log(result.account[index]['address']);
                            setAddress(result.account[index]['address']);
                        }
                    }
                }
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    return (
        <div>
            <ToastContainer />
            {loading ? (
                <div className='d-flex flex-column flex-root' id='loading-section'>
                    <div className='row justify-content-center align-items-center overlay p-0 m-0' id='overlay'>
                        <div className='col col-sm-6 col-lg-12 h-100 p-0'>
                            <div className='bounce col-12'>
                                <div className='bounce1' />
                                <div className='bounce2' />
                                <div className='bounce3' />
                            </div>
                            <h1 className='col loading-txt'>กรุณารอสักครู่</h1>
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                    <div className='row justify-content-between m-0 mb-7'>
                        <Link to='/coins' className='btn btn-link-primary my-auto'>
                            <i className='fas fa-angle-double-left icon-md py-2' />ย้อนกลับ
                        </Link>
                    </div>
                    <div className='row justify-content-center m-0'>
                        <div className='col-12 p-0 text-center text-white'>
                            <div className='font-size-h1 mb-5'>รับเหรียญ</div>
                        </div>
                        {address !== '' ? (
                            <>
                                <div className='col-md-3 col-10 p-7 bg-light text-center'>
                                    <QRCode
                                        value={address}
                                        size={256}
                                        eyeRadius={10}
                                        // logoImage={toAbsoluteUrl('/media/logos/new-logo.png')}
                                        logoWidth={100}
                                        style={`border: 2px solid #000000`}
                                    />
                                    <div className='row justify-content-end m-0 mt-5'>
                                        <button
                                            type='button'
                                            className='btn btn-light btn-hover-primary btn-sm mx-2'
                                            onClick={handleDownload}
                                        >
                                            บันทึก
                                        </button>
                                    </div>
                                </div>
                                <div className='col-12 row justify-content-center mt-7 m-0 p-0'>
                                    <div className='col-md-4 row m-0 p-0 justify-content-between'>
                                        <div className='font-size-h1 col-md-5 col-12 text-white'>Wallet ID</div>
                                        <div className='input-group col-md-7 col-12'>
                                            <input
                                                type='text'
                                                className='form-control'
                                                value={address}
                                                id='walletid'
                                                readOnly
                                            />
                                            <div className='input-group-append'>
                                                <button
                                                    type='button'
                                                    className='btn btn-secondary'
                                                    onClick={copyText}
                                                    disabled={copy}
                                                >
                                                    <i className='far fa-copy' />
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <div className='row align-items-center col-12 p-0 m-0 min-h-500px'>
                                <div className='text-center col-12 p-0'>
                                    <i className='text-white fas fa-exclamation-circle fa-5x' />
                                    <h1 className='col mt-5 text-white'>ไม่พบบัญชีเหรียญนี้ของคุณ กรุณาลองใหม่ภายหลัง</h1>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}
