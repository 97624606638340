import React, { useEffect, useMemo } from 'react';
import objectPath from 'object-path';
import { Link, NavLink } from 'react-router-dom';
// import { useLocation } from 'react-router';

import {
    toAbsoluteUrl
    // checkIsActive
} from '../../../../_metronic/_helpers';
// LayoutContext
import { useHtmlClassService } from '../../../../_metronic/layout/_core/MetronicLayout';
// Import Layout components
import { HeaderMobile } from '../../../../_metronic/layout/components/header-mobile/HeaderMobile';
import { Footer } from '../../../../_metronic/layout/components/footer/Footer';
import { LayoutInit } from '../../../../_metronic/layout/components/LayoutInit';
import { ScrollTop } from '../../../../_metronic/layout/components/extras/ScrollTop';
// import { HeaderMenuWrapper } from "../../../../_metronic/layout/components/header/header-menu/HeaderMenuWrapper";

export function Homepage() {
    // const location = useLocation();
    const uiService = useHtmlClassService();
    const layoutProps = useMemo(
        () => {
            return {
                layoutConfig: uiService.config,
                selfLayout: objectPath.get(uiService.config, 'self.layout'),
                asideDisplay: objectPath.get(uiService.config, 'aside.self.display'),
                subheaderDisplay: objectPath.get(uiService.config, 'subheader.display'),
                desktopHeaderDisplay: objectPath.get(uiService.config, 'header.self.fixed.desktop'),
                contentCssClasses: uiService.getClasses('content', true),
                contentContainerClasses: uiService.getClasses('content_container', true),
                contentExtended: objectPath.get(uiService.config, 'content.extended'),
                headerClasses: uiService.getClasses('header', true),
                headerAttributes: uiService.getAttributes('header'),
                headerContainerClasses: uiService.getClasses('header_container', true),
                headerMenuSelfDisplay: objectPath.get(uiService.config, 'header.menu.self.display') === true,
                headerMobileCssClasses: uiService.getClasses('header_mobile', true),
                headerMobileAttributes: uiService.getAttributes('header_mobile'),
                menuHeaderDisplay: objectPath.get(uiService.config, 'header.menu.self.display'),
                ktMenuClasses: uiService.getClasses('header_menu', true),
                ulClasses: uiService.getClasses('header_menu_nav', true)
            };
        },
        [ uiService ]
    );

    useEffect(() => {
        const script = document.createElement('script');
        script.src = 'https://widget.nomics.com/embed.js';
        script.async = true;
        document.body.appendChild(script);
        // $(document).ready(function() {
        //     $('#bnb-graph').addClass('col-12 mx-auto');
        // });
        // document.getElementById('bnb-graph').classList.add('col-12 mx-auto')
        return () => {
            document.body.removeChild(script);
            // document.getElementById('bnb-graph').classList.add('col-12 mx-auto')
        };
    }, []);

    // const getMenuItemActive = (url) => {
    //     return checkIsActive(location, url) ? 'menu-item-active' : '';
    // };
    return (
        <div>
            {/* <HeaderMobile /> */}
            <div
                id='kt_header_mobile'
                className={`header-mobile ${layoutProps.headerMobileCssClasses}`}
                {...layoutProps.headerMobileAttributes}
            >
                <Link to='/'>
                    <img
                        alt='Logo'
                        className='logo-default max-h-30px'
                        src={toAbsoluteUrl('/media/logos/main-logo-light.png')}
                    />
                </Link>
                <div className='d-flex align-items-center'>
                    <NavLink className='btn btn-danger rounded-lg mx-3' to='/auth/register'>
                        สมัครสมาชิก
                    </NavLink>
                    <NavLink className='btn btn-secondary rounded-lg mx-3' to='/auth/login'>
                        เข้าสู่ระบบ
                    </NavLink>
                </div>
            </div>
            <div className='d-flex flex-column flex-root'>
                <div className='d-flex flex-row flex-column-fluid page'>
                    <div className='d-flex flex-column flex-row-fluid wrapper' id='kt_wrapper'>
                        <div
                            className={`header ${layoutProps.headerClasses}`}
                            id='kt_header'
                            {...layoutProps.headerAttributes}
                        >
                            <div
                                className={` ${layoutProps.headerContainerClasses} d-flex align-items-stretch justify-content-between`}
                            >
                                <div className='d-flex align-items-stretch mr-3'>
                                    <div className='header-logo'>
                                        <Link to='/'>
                                            <img
                                                className='logo-default max-h-40px'
                                                alt='Logo'
                                                src={toAbsoluteUrl('/media/logos/main-logo-light.png')}
                                            />
                                        </Link>
                                    </div>
                                    {/* {layoutProps.menuHeaderDisplay && <HeaderMenuWrapper />} */}
                                    {/* <div className="header-menu-wrapper header-menu-wrapper-left" id="kt_header_menu_wrapper">
                                            <div
                                                id="kt_header_menu"
                                                className={`header-menu header-menu-left header-menu-mobile ${layoutProps.ktMenuClasses}`}
                                                {...layoutProps.headerMenuAttributes}
                                            >
                                                <ul className={`menu-nav ${layoutProps.ulClasses}`}>
                                                    <li className={`menu-item menu-item-rel ${getMenuItemActive('/dashboard')}`}>
                                                        <NavLink className="menu-link" to="/dashboard">
                                                            <span className="menu-text">Dashboard</span>
                                                            {layoutProps.rootArrowEnabled && (<i className="menu-arrow" />)}
                                                        </NavLink>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div> */}
                                </div>
                                <div className='topbar'>
                                    <div className='topbar-item'>
                                        <NavLink className='btn btn-danger rounded-lg mx-3' to='/auth/register'>
                                            สมัครสมาชิก
                                        </NavLink>
                                        <NavLink className='btn btn-secondary rounded-lg mx-3' to='/auth/login'>
                                            เข้าสู่ระบบ
                                        </NavLink>
                                    </div>
                                </div>
                                {/*begin::Topbar*/}
                                {/* <Topbar /> */}
                                {/*end::Topbar*/}
                            </div>
                        </div>
                        <div
                            id='kt_content'
                            className={`content ${layoutProps.contentCssClasses} d-flex flex-column flex-column-fluid py-7 min-vh-100`}
                        >
                            <div className='d-flex flex-column-fluid'>
                                <div className={layoutProps.contentContainerClasses}>
                                    <div className='row' id='homepage'>
                                        {/* <header className='masthead text-center text-white col-12 mb-7'>
                                            <div className='masthead-content'>
                                                <div className='container px-5'>
                                                    <h1 className='masthead-heading mb-0'>One Page Wonder</h1>
                                                    <h2 className='masthead-subheading mb-0'>
                                                        Will Rock Your Socks Off
                                                    </h2>
                                                    <NavLink className='btn btn-primary btn-pill px-12 py-5 my-5' to='/auth/register'>
                                                        สมัครสมาชิก
                                                    </NavLink>
                                                </div>
                                            </div>
                                            <div className='bg-circle-1 bg-circle' />
                                            <div className='bg-circle-2 bg-circle' />
                                            <div className='bg-circle-3 bg-circle' />
                                            <div className='bg-circle-4 bg-circle' />
                                        </header> */}
                                        {/* <section className='why-us col-12' id='punsook-shop'>
                                            <div className='container-fluid aos-init aos-animate' data-aos='fade-up'>
                                                <div className='row justify-content-center'>
                                                    <div className='row col-lg-10'>
                                                        <div
                                                            className='col-lg-3 row m-0 justify-content-center align-items-center img d-block d-lg-none aos-init aos-animate'
                                                            data-aos='zoom-in'
                                                            data-aos-delay={150}
                                                        >
                                                            <img
                                                                className='col-12 max-h-400px max-h-md-500px'
                                                                src='assets/media/pages/homepage/punsookshop.png'
                                                            />
                                                        </div>
                                                        <div className='col-lg-7 d-flex flex-column justify-content-center align-items-stretch'>
                                                            <div className='content text-md-left text-center'>
                                                                <h3 className='cus-text font-size-48px'>
                                                                    Punsook Shop
                                                                </h3>
                                                                <p className='cus-text font-size-h6 m-0'>
                                                                    {' '}
                                                                    ร้านค้าออนไลน์สำหรับนักธุรกิจรุ่นใหม่{' '}
                                                                </p>
                                                                <p className='cus-text font-size-h3 font-weight-bolder m-0'>
                                                                    {' '}
                                                                    ไม่ต้อง Stock สินค้า{' '}
                                                                </p>
                                                                <p className='cus-text font-size-h6'>
                                                                    {' '}
                                                                    คุณพร้อมมาเป็นตัวแทน Punsook Shop รึยัง?{' '}
                                                                </p>
                                                            </div>
                                                            <div className='row justify-content-center m-0 mt-7'>
                                                                <a
                                                                    className='btn btn-primary btn-cus-green btn-pill px-8 py-4 font-size-h4'
                                                                    href='https://member.simk4.com'
                                                                >
                                                                    {' '}
                                                                    คลิกเลยยย!{' '}
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div
                                                            className='col-lg-5 row m-0 justify-content-center align-items-center img d-none d-lg-block aos-init aos-animate'
                                                            data-aos='zoom-in'
                                                            data-aos-delay={150}
                                                        >
                                                            <img
                                                                className='max-h-400px max-h-md-500px'
                                                                src='assets/media/pages/homepage/punsookshop.png'
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </section> */}
                                        <div className='col-md-7 col-12 mx-auto'>
                                            <div
                                                className='nomics-ticker-widget'
                                                id='bnb-graph'
                                                data-name='Coin'
                                                data-base='BNB'
                                                data-quote='USDT'
                                            />
                                        </div>
                                        <div className='col-md-8 col-12 text-center mx-auto mt-7'>
                                            <img className='max-h-md-500px max-h-300px' src={toAbsoluteUrl('/media/misc/bank-image.jpg')} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
            <ScrollTop />
            <LayoutInit />
        </div>
    );
}
