import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { Link, useHistory } from 'react-router-dom';

import NumberFormat from 'react-number-format';

import Swal from 'sweetalert2/dist/sweetalert2.js';

// import { toAbsoluteUrl } from '../../../../_metronic/_helpers';

import config from '../../../config';

const SwalBs = Swal.mixin({
    customClass: {
        popup: 'rounded-lg',
        confirmButton: 'btn btn-success rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto',
        cancelButton: 'btn btn-danger rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto'
    },
    buttonsStyling: false
});

export function WithdrawMain(props) {
    const { user } = useSelector((state) => state.auth);
    let history = useHistory();

    const [ loading, setLoading ] = useState(false);
    const [ coin, setCoin ] = useState('');
    const [ address, setAddress ] = useState('');
    const [ withdrawaddress, setWithdrawAddress ] = useState('');
    const [ withdrawnetwork, setWithdrawNetwork ] = useState('');
    const [ balance, setBalance ] = useState('');

    const getQueryVariable = (variable) => {
        var query = props.location.search.substring(1);
        var vars = query.split('&');
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split('=');
            if (pair[0] === variable) {
                return pair[1];
            }
        }
        return false;
    };

    useEffect(() => {
        let address = getQueryVariable('address');
        let coin = getQueryVariable('coin');
        let withdrawaddress = getQueryVariable('withdrawaddress');
        let withdrawnetwork = getQueryVariable('withdrawnetwork');
        let balance = getQueryVariable('balance');
        if (address) {
            setAddress(address);
        }
        if (coin) {
            setCoin(coin);
        }
        if (balance) {
            setBalance(balance);
        }
        if (withdrawaddress) {
            setWithdrawAddress(withdrawaddress);
        }
        if (withdrawnetwork) {
            setWithdrawNetwork(withdrawnetwork);
        }
    }, []);

    const btnWithdraw = () => {
        setLoading(true);
        fetch(config.crypto_url + 'account/withdraw', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify({
                token: user.token,
                account_address: address
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                if (result.responsecode === 0) {
                    SwalBs.fire({
                        // title: 'ยกเลิกคำสั่ง',
                        html: `ดำเนินการถอนเหรียญเรียบร้อย`,
                        icon: 'success',
                        // showCancelButton: true,
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        heightAuto: false
                    }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                            history.push('/coins');
                        }
                    });
                } else {
                    SwalBs.fire({
                        // title: 'ยกเลิกคำสั่ง',
                        html: `ไม่สามารถถอนเหรียญได้ กรุณาลองใหม่อีกครั้ง`,
                        icon: 'warning',
                        // showCancelButton: true,
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        heightAuto: false
                    });
                }
            })
            .catch((error) => {
                setLoading(false);
                SwalBs.fire({
                    // title: 'ยกเลิกคำสั่ง',
                    html: `มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง`,
                    icon: 'error',
                    // showCancelButton: true,
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    heightAuto: false
                });
            });
    };
    return (
        <div>
            {loading ? (
                <div className='d-flex flex-column flex-root' id='loading-section'>
                    <div className='row justify-content-center align-items-center overlay p-0 m-0' id='overlay'>
                        <div className='col col-sm-6 col-lg-12 h-100 p-0'>
                            <div className='bounce col-12'>
                                <div className='bounce1' />
                                <div className='bounce2' />
                                <div className='bounce3' />
                            </div>
                            <h1 className='col loading-txt'>กรุณารอสักครู่</h1>
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                    <div className='row justify-content-between m-0 mb-7'>
                        <Link to='/coins' className='btn btn-link-primary my-auto'>
                            <i className='fas fa-angle-double-left icon-md py-2' />ย้อนกลับ
                        </Link>
                    </div>
                    <div className='row justify-content-center m-0'>
                        <div className='col-12 p-0 text-center text-white'>
                            <div className='font-size-h1 mb-5'>ถอนเหรียญ</div>
                        </div>
                        <div className='col-md-8 col-12 p-0'>
                            <div className='form-group row mx-0'>
                                <label className='col-form-label col-lg-3 text-white'>ประเภทเหรียญ</label>
                                <div className='col-lg-9 mx-auto my-auto text-white'>
                                    {coin === 'bnb' ? 'BNB' : coin === 'starverse' ? 'StarVerse' : null}
                                </div>
                            </div>
                            <div className='form-group row mx-0'>
                                <label className='col-form-label col-lg-3 text-white'>จำนวนเหรียญที่ถอน</label>
                                <div className='col-lg-9 mx-auto my-auto text-white'>
                                    <NumberFormat
                                        value={parseFloat(balance)}
                                        displayType={'text'}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        thousandSeparator={true}
                                        // suffix={' บาท'}
                                    />
                                </div>
                            </div>
                            <div className='form-group row mx-0'>
                                <label className='col-form-label col-lg-3 text-white'>ค่าธรรมเนียม</label>
                                <div className='col-lg-9 mx-auto my-auto text-white'>XXX</div>
                            </div>
                            <div className='form-group row mx-0'>
                                <label className='col-form-label col-lg-3 text-white'>ถอนไปยัง</label>
                                <div className='col-lg-9 mx-auto my-auto text-white'>
                                    <label className='text-white'>Address</label>
                                    <div>{withdrawaddress}</div>
                                </div>
                            </div>
                            <div className='form-group row mx-0'>
                                <label className='col-form-label col-lg-3 text-transparent'>ถอนไปยัง</label>
                                <div className='col-lg-9 mx-auto my-auto text-white'>
                                    <label className='text-white'>Network</label>
                                    <div>{withdrawnetwork}</div>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 p-0 mt-7 text-center'>
                            <button
                                type='button'
                                className='btn btn-primary text-uppercase font-weight-bolder px-15 py-3 rounded-lg'
                                onClick={btnWithdraw}
                            >
                                ถอน
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
