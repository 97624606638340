import React, { useEffect, useState } from 'react';

import { useSelector } from 'react-redux';

import { Link } from 'react-router-dom';

import Select, { components } from 'react-select';

import Swal from 'sweetalert2/dist/sweetalert2.js';

import { PayResult } from './components';

import config from '../../../config';

const SwalBs = Swal.mixin({
    customClass: {
        popup: 'rounded-lg',
        confirmButton: 'btn btn-success rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto',
        cancelButton: 'btn btn-danger rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto'
    },
    buttonsStyling: false
});

const controlStyles = {
    borderRadius: '0.42rem',
    // padding: '5px',
    background: '#F3F6F9',
    color: 'white'
};

const ControlComponent = (props) => (
    <div style={controlStyles}>
        {/* {<p>Custom Control</p>} */}
        <components.Control {...props} />
    </div>
);

const customStyles = {
    menu: (provided, state) => ({
        ...provided,
        backgroundColor: '#F3F6F9'
    }),
    menuPortal: (base) => ({
        ...base,
        zIndex: 9999
    }),
    control: (provided, state) => ({
        ...provided,
        borderStyle: 'none',
        backgroundColor: 'transparent',
        boxShadow: 'none'
    })
    // singleValue: (provided, state) => {
    //     const opacity = state.isDisabled ? 0.5 : 1;
    //     const transition = "opacity 300ms";
    //     return { ...provided, opacity, transition };
    // },
};

const PriceList = [
    {
        label: '300 บาท',
        value: '1'
    },
    {
        label: '600 บาท',
        value: '2'
    },
    {
        label: '1,500 บาท',
        value: '3'
    },
    {
        label: '3,000 บาท',
        value: '4'
    },
    {
        label: '15,000 บาท',
        value: '5'
    }
];

const YaktPriceList = [
    {
        label: '900 บาท',
        value: '10000'
    },
    {
        label: '9000 บาท',
        value: '100000'
    },
    {
        label: '90000 บาท',
        value: '1000000'
    }
];

export function PayMain(props) {
    const { user } = useSelector((state) => state.auth);

    const [ loading, setLoading ] = useState(false);
    const [ page, setPage ] = useState('main');
    const [ customcoins, setCustomCoins ] = useState({});
    const [ coin, setCoin ] = useState('');
    const [ price, setPrice ] = useState({});
    const [ bnbamount, setBNBAmount ] = useState(0);
    const [ starverseamount, setStarVerseAmount ] = useState(0);
    const [ yaktamount, setYaktAmount ] = useState(0);
    const [ response, setResponse ] = useState({});

    const getQueryVariable = (variable) => {
        var query = props.location.search.substring(1);
        var vars = query.split('&');
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split('=');
            if (pair[0] === variable) {
                return pair[1];
            }
        }
        return false;
    };

    useEffect(() => {
        let queryparam = getQueryVariable('coin');
        if (queryparam) {
            if (queryparam === 'yakt') {
                setCoin('yakt');
            }
        }
    }, []);

    const selectPrice = (item) => {
        if (item) {
            setPrice(item);
            if (item.value === '1') {
                setBNBAmount(0.01);
                setStarVerseAmount(1000000);
            } else if (item.value === '2') {
                setBNBAmount(0.02);
                setStarVerseAmount(3000000);
            } else if (item.value === '3') {
                setBNBAmount(0.05);
                setStarVerseAmount(5000000);
            } else if (item.value === '4') {
                setBNBAmount(0.1);
                setStarVerseAmount(15000000);
            } else if (item.value === '5') {
                setBNBAmount(0.5);
                setStarVerseAmount(100000000);
            }
        }
    };

    const selectYaktPrice = (item) => {
        if (item) {
            setPrice(item);
            setYaktAmount(item.value);
        }
    };

    const createOrder = (yakt = false) => {
        if (Object.keys(price).length === 0) {
            SwalBs.fire({
                // title: 'ยกเลิกคำสั่ง',
                html: 'กรุณาเลือกจำนวนที่ต้องการบริจาคให้เรียบร้อย แล้วลองใหม่อีกครั้ง',
                icon: 'warning',
                // showCancelButton: true,
                confirmButtonText: 'รับทราบ',
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                heightAuto: false
            });
            return;
        }
        let param = {
            token: user.token,
            coin: []
        };
        if (!yakt) {
            param['coin'] = [
                {
                    coin_name: 'starverse',
                    quantity: starverseamount.toString()
                },
                {
                    coin_name: 'bnb',
                    quantity: bnbamount.toString()
                }
            ];
        } else {
            param['coin'] = [
                {
                    coin_name: 'yakt',
                    quantity: yaktamount.toString()
                }
            ];
        }
        setLoading(true);
        fetch(config.crypto_url + 'account/deposit', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify(param)
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                setResponse(result);
                setPage('result');
            })
            .catch((error) => {
                setLoading(false);
                setPage('result');
            });
    };

    return (
        <div>
            {loading ? (
                <div className='d-flex flex-column flex-root' id='loading-section'>
                    <div className='row justify-content-center align-items-center overlay p-0 m-0' id='overlay'>
                        <div className='col col-sm-6 col-lg-12 h-100 p-0'>
                            <div className='bounce col-12'>
                                <div className='bounce1' />
                                <div className='bounce2' />
                                <div className='bounce3' />
                            </div>
                            <h1 className='col loading-txt'>กรุณารอสักครู่</h1>
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                    {page === 'main' ? (
                        <div>
                            <div className='row justify-content-between m-0 mb-7'>
                                <Link to='/coins' className='btn btn-link-primary my-auto'>
                                    <i className='fas fa-angle-double-left icon-md py-2' />ย้อนกลับ
                                </Link>
                            </div>
                            <div className='row justify-content-center m-0'>
                                <div className='col-12 p-0 text-center text-white'>
                                    <div className='font-size-h1 mb-5'>บริจาค</div>
                                </div>
                                <div className='col-md-8 col-12 p-0'>
                                    <div className='form-group row mx-0'>
                                        <label className='col-form-label col-lg-3 text-white'>
                                            จำนวนที่ต้องการบริจาค
                                        </label>
                                        <div className='col-lg-9'>
                                            <Select
                                                components={{
                                                    Control: ControlComponent
                                                }}
                                                noOptionsMessage={() => 'ไม่พบข้อมูล'}
                                                value={price}
                                                options={coin !== 'yakt' ? PriceList : YaktPriceList}
                                                placeholder='เลือก'
                                                id='account'
                                                onChange={coin !== 'yakt' ? selectPrice : selectYaktPrice}
                                                // isClearable={true}
                                                menuPortalTarget={document.body}
                                                styles={customStyles}
                                            />
                                        </div>
                                    </div>
                                    {coin !== 'yakt' ? (
                                        <>
                                            <div className='form-group row mx-0'>
                                                <label className='col-form-label col-lg-3 text-white'>
                                                    จำนวนเหรียญ BNB
                                                </label>
                                                <div className='col-lg-9'>
                                                    <input
                                                        type='text'
                                                        className='form-control'
                                                        readOnly
                                                        value={bnbamount}
                                                    />
                                                </div>
                                            </div>
                                            <div className='form-group row mx-0'>
                                                <label className='col-form-label col-lg-3 text-white'>
                                                    จำนวนเหรียญ StarVerse
                                                </label>
                                                <div className='col-lg-9'>
                                                    <input
                                                        type='text'
                                                        className='form-control'
                                                        readOnly
                                                        value={starverseamount}
                                                    />
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <div className='form-group row mx-0'>
                                            <label className='col-form-label col-lg-3 text-white'>
                                                จำนวนเหรียญ YakToken
                                            </label>
                                            <div className='col-lg-9'>
                                                <input
                                                    type='text'
                                                    className='form-control'
                                                    readOnly
                                                    value={yaktamount}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div className='col-12 p-0 mt-7 text-center'>
                                    <button
                                        type='button'
                                        className='btn btn-primary text-uppercase font-weight-bolder px-15 py-3 rounded-lg'
                                        onClick={coin !== 'yakt' ? () => createOrder(false) : () => createOrder(true)}
                                    >
                                        เลือก
                                    </button>
                                </div>
                            </div>
                        </div>
                    ) : page === 'result' ? (
                        <PayResult
                            coin={coin}
                            response={response}
                            bnbamount={bnbamount}
                            starverseamount={starverseamount}
                            yaktamount={yaktamount}
                            price={price.label}
                        />
                    ) : null}
                </div>
            )}
        </div>
    );
}
