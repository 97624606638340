import React from 'react';

import Select, { components } from 'react-select';

const controlStyles = {
    borderRadius: '0.42rem',
    // padding: '5px',
    background: '#F3F6F9',
    color: 'white'
};

const ControlComponent = (props) => (
    <div style={controlStyles}>
        {/* {<p>Custom Control</p>} */}
        <components.Control {...props} />
    </div>
);

const customStyles = {
    menu: (provided, state) => ({
        ...provided,
        backgroundColor: '#F3F6F9'
    }),
    menuPortal: (base) => ({
        ...base,
        zIndex: 9999
    }),
    control: (provided, state) => ({
        ...provided,
        borderStyle: 'none',
        backgroundColor: 'transparent',
        boxShadow: 'none'
    })
    // singleValue: (provided, state) => {
    //     const opacity = state.isDisabled ? 0.5 : 1;
    //     const transition = "opacity 300ms";
    //     return { ...provided, opacity, transition };
    // },
};

export function DonateForm(props) {
    const selectCoin = (item) => {
        if (item) {
            props.setCoin(item);
        }
    };
    return (
        <>
            <div className='row justify-content-center col-md-6 col-12 p-0 mt-7 m-0'>
                <div className='col-12 p-0 form-group'>
                    <div className='input-group'>
                        <div className='input-group-prepend'>
                            <span className='input-group-text'>
                                <i className='fas fa-user-circle icon-lg mr-3' /> Wallet ID
                            </span>
                        </div>
                        <input type='text' className='form-control' value={props.reciever} disabled='disabled' />
                    </div>
                </div>
                <div className='col-12 p-0 form-group'>
                    <label className='text-white'>ประเภทเหรียญ</label>
                    <Select
                        components={{
                            Control: ControlComponent
                        }}
                        noOptionsMessage={() => 'ไม่พบข้อมูล'}
                        value={props.coin}
                        options={props.coins}
                        placeholder='เลือก'
                        id='account'
                        onChange={selectCoin}
                        isOptionDisabled={(option) => option.disabled}
                        // isClearable={true}
                        menuPortalTarget={document.body}
                        styles={customStyles}
                    />
                </div>
                <div className='col-12 p-0 form-group'>
                    <label className='text-white'>จำนวนเหรียญ</label>
                    <input
                        type='text'
                        inputMode='tel'
                        className='form-control'
                        value={props.amount}
                        onChange={(e) => props.setAmount(e.target.value)}
                        onKeyPress={(event) => {
                            if (!/[0-9.]/.test(event.key)) {
                                if (event.key !== 'Enter') {
                                    event.preventDefault();
                                }
                            }
                        }}
                    />
                </div>
                <div className='col-12 row p-0 mt-10 m-0 justify-content-between'>
                    <button
                        type='button'
                        className='btn btn-secondary text-uppercase font-weight-bolder px-15 py-3 rounded-lg'
                        onClick={props.btnBack}
                    >
                        ก่อนหน้า
                    </button>
                    <button
                        type='button'
                        className='btn btn-primary text-uppercase font-weight-bolder px-15 py-3 rounded-lg'
                        onClick={props.btnNext}
                    >
                        ถัดไป
                    </button>
                </div>
            </div>
        </>
    );
}
