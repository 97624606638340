import React, { useEffect, useState, useRef } from 'react';

import { useSelector } from 'react-redux';

import { Link } from 'react-router-dom';

import { useQrDecode } from 'react-qr-hooks';

import Swal from 'sweetalert2/dist/sweetalert2.js';

import { toAbsoluteUrl } from '../../../../_metronic/_helpers';

import { DonateForm, DonateResult } from './components';

import config from '../../../config';

const SwalBs = Swal.mixin({
    customClass: {
        popup: 'rounded-lg',
        confirmButton: 'btn btn-success rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto',
        cancelButton: 'btn btn-danger rounded-lg font-weight-bold text-uppercase px-9 py-4 mx-auto'
    },
    buttonsStyling: false
});

export function DonateMain(props) {
    const { user } = useSelector((state) => state.auth);
    const inputFileRef = useRef(null);

    const [ loading, setLoading ] = useState(false);
    const [ page, setPage ] = useState('main');
    const [ coins, setCoins ] = useState([]);
    const [ coin, setCoin ] = useState({});
    const [ amount, setAmount ] = useState(0);
    const [ filebase64, setFileBase64 ] = useState('');
    const [ qrcode, setQrcode ] = useState('');
    const [ type, setType ] = useState('pic');
    const [ address, setAddress ] = useState('');
    const [ addressdetail, setAddressDetail ] = useState({});
    const [ response, setResponse ] = useState({});

    const decoded = useQrDecode(qrcode);

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        const base64 = await convertBase64(file);
        setFileBase64(base64.split(',')[1]);
        setQrcode(base64);
        // console.log(decoded);
        // setAddress(decoded);
    };

    const btnBack = () => {
        if (page === 'form') {
            setFileBase64('');
            setQrcode('');
            setType('pic');
            setAddress('');
            setCoin({});
            setAmount(0);
            setPage('main');
        }
    };

    const btnNext = () => {
        if (page === 'main') {
            if (address === '' && decoded === null) {
                SwalBs.fire({
                    // title: 'ยกเลิกคำสั่ง',
                    html: `กรุณากรอก Wallet ID ให้เรียบร้อย แล้วลองใหม่อีกครั้ง`,
                    icon: 'warning',
                    // showCancelButton: true,
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    heightAuto: false
                });
                return;
            }
            getAddressDetail();
        } else if (page === 'form') {
            if (Object.keys(coin).length === 0) {
                SwalBs.fire({
                    // title: 'ยกเลิกคำสั่ง',
                    html: `กรุณาเลือกประเภทเหรียญให้เรียบร้อย แล้วลองใหม่อีกครั้ง`,
                    icon: 'warning',
                    // showCancelButton: true,
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    heightAuto: false
                });
                return;
            }
            if (amount === '') {
                SwalBs.fire({
                    // title: 'ยกเลิกคำสั่ง',
                    html: `กรุณากรอกจำนวนเหรียญให้เรียบร้อย แล้วลองใหม่อีกครั้ง`,
                    icon: 'warning',
                    // showCancelButton: true,
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    heightAuto: false
                });
                return;
            }
            transferCoin();
        }
    };

    useEffect(() => {
        getBalace();
    }, []);

    const getBalace = () => {
        setLoading(true);
        fetch(config.crypto_url + 'account/get-balance', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify({
                token: user.token
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                if (result.responsecode === 0) {
                    let coin_list = [];
                    for (const index in result.account) {
                        coin_list.push({
                            label: result.account[index]['type'],
                            value: result.account[index]['address']
                        })
                    }
                    setCoins(coin_list);
                }
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const getAddressDetail = () => {
        setLoading(true);
        fetch(config.crypto_url + 'account/validate-address', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify({
                token: user.token,
                account_address: type === 'pic' ? decoded : address
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                // console.log(result);
                if (result.responsecode === 0) {
                    setAddressDetail(result.account);
                    let coin_list = coins;
                    for (const index in coins) {
                        if (coins[index]['label'] !== result.account.account_type) {
                            coin_list[index]['disabled'] = true;
                        }
                    }
                    // console.log(coin_list);
                    setCoins(coin_list);
                    setPage('form');
                } else {
                    SwalBs.fire({
                        // title: 'ยกเลิกคำสั่ง',
                        html: `มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง`,
                        icon: 'warning',
                        // showCancelButton: true,
                        confirmButtonText: 'รับทราบ',
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        heightAuto: false
                    });
                    return;
                }
            })
            .catch((error) => {
                setLoading(false);
                SwalBs.fire({
                    // title: 'ยกเลิกคำสั่ง',
                    html: `มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง`,
                    icon: 'error',
                    // showCancelButton: true,
                    confirmButtonText: 'รับทราบ',
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    heightAuto: false
                });
                return;
            });
    }

    const transferCoin = () => {
        setLoading(true);
        fetch(config.crypto_url + 'account/transfer', {
            method: 'post',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
                // secret: config.secret,
            },
            body: JSON.stringify({
                token: user.token,
                account_source: coin.value,
                account_dest: type === 'pic' ? decoded : address,
                balance: amount
            })
        })
            .then((response) => response.json())
            .then((result) => {
                setLoading(false);
                setResponse(result);
                setPage('result');
            })
            .catch((error) => {
                setLoading(false);
                setPage('result');
            });
    }

    return (
        <div>
            {loading ? (
                <div className='d-flex flex-column flex-root' id='loading-section'>
                    <div className='row justify-content-center align-items-center overlay p-0 m-0' id='overlay'>
                        <div className='col col-sm-6 col-lg-12 h-100 p-0'>
                            <div className='bounce col-12'>
                                <div className='bounce1' />
                                <div className='bounce2' />
                                <div className='bounce3' />
                            </div>
                            <h1 className='col loading-txt'>กรุณารอสักครู่</h1>
                        </div>
                    </div>
                </div>
            ) : (
                <div>
                    <div className='row justify-content-between m-0 mb-7'>
                        <Link to='/coins' className='btn btn-link-primary my-auto'>
                            <i className='fas fa-angle-double-left icon-md py-2' />ย้อนกลับ
                        </Link>
                    </div>
                    <div className='row justify-content-center m-0'>
                        <div className='col-12 p-0 text-center text-white'>
                            <div className='font-size-h1 mb-5'>โอนเหรียญ</div>
                        </div>
                        <div className='col-md-8 col-12 p-0 custom-progressbar'>
                            <div className='progressbar-wrapper'>
                                {page === 'main' ? (
                                    <ul className='progressbar-min'>
                                        <li className='mb-3'>
                                            <span className='text-white'>
                                                <div className='step-title'>โอนให้</div>
                                            </span>
                                        </li>
                                    </ul>
                                ) : page === 'form' ? (
                                    <ul className='progressbar-step'>
                                        <li className='mb-3'>
                                            <span className='text-white'>
                                                <div className='step-title'>รายละเอียด</div>
                                            </span>
                                        </li>
                                    </ul>
                                ) : page === 'result' ? (
                                    <ul className='progressbar-max'>
                                        <li className='mb-3'>
                                            <span className='text-white'>
                                                <div className='step-title'>ผลลัพธ์</div>
                                            </span>
                                        </li>
                                    </ul>
                                ) : null}
                                {/* <ul className='progressbar-step'>
                                    <li className='mb-3'>
                                        <span>
                                            <div className='step-title'>ตรวจสอบ</div>
                                            เบอร์โทรศัพท์
                                        </span>
                                    </li>
                                </ul>
                                <ul className='progressbar-step'>
                                    <li className='mb-3'>
                                        <span>
                                            <div className='step-title'>ตรวจสอบ</div>
                                            บัตรประจำตัวประชาชน
                                        </span>
                                    </li>
                                </ul>
                                <ul className='progressbar-step'>
                                    <li className='mb-3'>
                                        <span>
                                            <div className='step-title'>ตรวจสอบ</div>
                                            ข้อมูลส่วนตัว
                                        </span>
                                    </li>
                                </ul>
                                <ul className='progressbar-step'>
                                    <li className='mb-3'>
                                        <span>
                                            <div className='step-title'>ตรวจสอบ</div>
                                            อัตลักษณ์
                                        </span>
                                    </li>
                                </ul>
                                <ul className='progressbar-step'>
                                    <li className='mb-3'>
                                        <span>
                                            <div className='step-title'>เงื่อนไข</div>
                                            การลงทะเบียนกรณี ตรวจสอบอัตลักษณ์ไม่ผ่าน
                                        </span>
                                    </li>
                                </ul>
                                <ul className='progressbar-max'>
                                    <li className='mb-3'>
                                        <span>
                                            <div className='step-title'>ผลลัพธ์</div>
                                            การลงทะเบียน
                                        </span>
                                    </li>
                                </ul> */}
                            </div>
                        </div>
                        {page === 'main' ? (
                            <>
                                <div className='row justify-content-center col-md-6 col-12 p-0 mt-7 m-0'>
                                    <div className='col-12 p-0 form-group row justify-content-center mx-0 button-group'>
                                        <div className='selector col-lg-9 mx-auto'>
                                            <div
                                                className={`cursor-pointer selection${type === 'pic'
                                                    ? ' selected'
                                                    : ''}`}
                                                onClick={() => setType('pic')}
                                            >
                                                รูปภาพ
                                            </div>
                                            <div
                                                className={`cursor-pointer selection${type === 'input'
                                                    ? ' selected'
                                                    : ''}`}
                                                onClick={() => setType('input')}
                                            >
                                                กรอกข้อมูล
                                            </div>
                                        </div>
                                    </div>
                                    <div className={`file-upload${type === 'pic' ? '' : ' d-none'}`}>
                                        <div className='text-center upload-img'>
                                            <img
                                                className={`d-example${filebase64 !== '' ? ' d-none' : ''}`}
                                                src={toAbsoluteUrl('/media/misc/blank.png')}
                                                onClick={() => inputFileRef.current.click()}
                                            />
                                            <div className={`preview${filebase64 === '' ? ' d-none' : ''}`}>
                                                <img
                                                    id='peviewidcart'
                                                    src={`data:image/png;base64,${filebase64}`}
                                                    onClick={() => inputFileRef.current.click()}
                                                />
                                            </div>
                                            <p className='mt-3 text-white'>ถ่ายภาพคิวอาร์โค้ดกระเป๋าที่ต้องการโอน</p>
                                            <input
                                                className='d-none'
                                                type='file'
                                                accept='.png, .jpg, .jpeg'
                                                ref={inputFileRef}
                                                onChange={handleFileChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={`row justify-content-center col-md-7 col-12 p-0 mt-7 m-0${type === 'pic'
                                        ? ''
                                        : ' d-none'}`}
                                >
                                    <div className='col-12 row m-0 p-0 justify-content-between'>
                                        <div className='font-size-h3 col-md-5 col-12 text-white'>Wallet ID</div>
                                        <div className='col-md-7 col-12'>
                                            <input type='text' className='form-control' value={decoded} readOnly />
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className={`row justify-content-center col-md-7 col-12 p-0 mt-7 m-0${type ===
                                    'input'
                                        ? ''
                                        : ' d-none'}`}
                                >
                                    <div className='col-12 row m-0 p-0 justify-content-between'>
                                        <div className='font-size-h3 col-md-5 col-12 text-white'>Wallet ID</div>
                                        <div className='col-md-7 col-12'>
                                            <input
                                                type='text'
                                                className='form-control'
                                                value={address}
                                                onChange={(e) => setAddress(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='col-12 p-0 mt-10 text-center'>
                                    <button
                                        type='button'
                                        className='btn btn-primary text-uppercase font-weight-bolder px-15 py-3 rounded-lg'
                                        onClick={btnNext}
                                    >
                                        ถัดไป
                                    </button>
                                </div>
                            </>
                        ) : page === 'form' ? (
                            <DonateForm
                                // user={user}
                                // setLoading={setLoading}
                                addressdetail={addressdetail}
                                coin={coin}
                                setCoin={setCoin}
                                coins={coins}
                                amount={amount}
                                setAmount={setAmount}
                                reciever={type === 'pic' ? decoded : address}
                                btnBack={btnBack}
                                btnNext={btnNext}
                            />
                        ) : page === 'result' ? (
                            <DonateResult response={response} />
                        ) : null}
                    </div>
                </div>
            )}
        </div>
    );
}
